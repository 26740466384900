import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './styles/variables.scss'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import VueCookies from 'vue-cookies';
import MainLayout from "@/layout/MainLayout.vue";
import EmptyLayout from "@/layout/EmptyLayout.vue";
import ElementPlus from 'element-plus'

const app = createApp(App);

app.component('default-layout', MainLayout);
app.component('empty-layout', EmptyLayout);

app.use(router).use(ElementPlus).use(VueCookies).mount('#app')
