<template>
  <div class="login-page">
    <el-button type="primary"  tag="a" size="large" :href="`https://giant-administrator-14g8gg7nyh69.site/builder/users/login`">
      Войти через SSO
    </el-button>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
@use "@/styles/assembly" as *;
@use "@/styles/variables" as *;
.login-page{
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

</style>
