<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script setup lang="ts">
import {computed} from "vue";
import {useRouter} from "vue-router";

const { currentRoute } = useRouter();

const layout = computed(
    () => currentRoute.value.meta.layout || 'default-layout'
);
</script>

<style lang="scss">
@use "@/styles/assembly" as *;
@use "@/styles/variables" as *;

html.dark {
  #app{
    background: transparent !important;
  }
}

body{
  font-family: 'Qanelas', serif;
  margin: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  #app{
    width: 100%;
    height: 100%;
    overflow: scroll;
    background: #ecf5ff91;
  }
}
</style>