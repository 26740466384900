import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../views/LoginPage.vue'
import {ref} from "vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta:{layout: 'empty'}
  },
  {
    path: '/',
    name: 'builder',
    meta: {auth: true},
    component: () => import('../views/MainPage.vue')
  },{
    path: '/toggles',
    name: 'toggles',
    meta: {auth: true},
    component: () => import('../views/TogglesPages.vue')
  },{
    path: '/logs',
    name: 'logs',
    meta: {auth: true},
    component: () => import('../views/LogsPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next)=>{
  const requireAuth = to.matched.some(record => record.meta.auth)
  let authorized = ref(false)

  // if(to.path === '/toggles'){
  //   fetch(`https://giant-administrator-14g8gg7nyh69.site/toggles/users/me`,{credentials: 'include'}).then(result=> {
  //     authorized.value = result.status !== 401 && result.status !== 403
  //     if(requireAuth && !authorized.value) next('/login')
  //     else next()
  //   })
  // }
  // else if(to.path === '/'){
  //   fetch(`https://giant-administrator-14g8gg7nyh69.site/builder/users/me`,{credentials: 'include'}).then(result=> {
  //     authorized.value = result.status !== 401 && result.status !== 403
  //     if(requireAuth && !authorized.value) next('/login')
  //     else next()
  //   })
  // }else next()
  fetch(`https://giant-administrator-14g8gg7nyh69.site/builder/users/me`,{credentials: 'include'}).then(result=> {
        authorized.value = result.status !== 401 && result.status !== 403
        if(requireAuth && !authorized.value) next('/login')
        else next()
      })
})

export default router
